import React, { useEffect, useRef, useState } from 'react';
import '../shared.scss';

import * as turf from '@turf/turf';
import { Mission } from '../../classes/Mission';
import { Waypoint } from '../../classes/Waypoint';
import MissionMap from '../../components/Map/MissionMap';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import checkSameCoordinatesValidator from '../../helpers/checkSameCoordinatesValidator';
import GenericService from '../../services/generic.service';
import MapOverlay from './MapOverlay/MapOverlay';
import { calculateDistanceBetweenPoints } from '../../helpers/waypointDistanceHelper';
import ReportLog from '../../components/ReportLog/ReportLog';
import MinimizedReportLog from '../../components/ReportLog/MinimizedReportLog/MinimizedReportLog';

export const MissionsContext = React.createContext(undefined);

export default function Missions() {
  const [maxWaypointId, setMaxWaypointId] = useState(0);
  const [drones, setDrones] = useState([]);
  const [missions, setMissions] = useState([]);
  const [editMissionId, setEditMissionId] = useState(0);
  const [selectedMissionId, setSelectedMissionId] = useState(0);
  const [selectedWaypoint, setSelectedWaypoint] = useState(0);
  const [missionValidationErrors, setMissionValidationErrors] = useState([]);
  const [waypoints, setWaypoints] = useState([]);
  const [photoArea, setPhotoArea] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null);
  const [lineStrings, setLineStrings] = useState(null);
  const [mapStyle, setMapStyle] = useState(localStorage.getItem('mapStyle') ?? 'streets-v11');
  const [currentAltitude, setCurrentAltitude] = useState(0);
  const [showAltitudeInput, setShowAltitudeInput] = useState(false);
  const [apps, setApps] = useState([]);
  const [favoriteApps, setFavoriteApps] = useState([]);
  const [selectAppPopup, setSelectAppPopup] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);
  const [showReportLog, setShowReportLog] = useState(false);
  const [showMinimizedReportLog, setShowMinimizedReport] = useState(false);

  const mapRef = useRef();

  const getMissions = () => {
    GenericService.MissionService.getMissions().then((response) => {
      setMissions(response);
    });
  };

  const getDrones = () => {
    GenericService.DroneService.getDrones().then((response) => {
      setDrones(response);
    });
  };

  const getFavoriteApps = () => {
    GenericService.AppStoreService.GetFavoriteApps().then((response) => {
      setFavoriteApps(response);
    });
  };

  const getApps = () => {
    GenericService.AppStoreService.GetAllApps().then((response) => {
      setApps(response);
    });
  };

  useEffect(() => {
    localStorage.setItem('mapStyle', mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    getMissions();
    getDrones();
    getFavoriteApps();
    getApps();
  }, []);

  useEffect(() => {
    if (selectedMissionId === 0 || selectedMissionId === undefined) {
      setWaypoints([]);
      setSelectedWaypoint(null);
    } else {
      const selectedMissionWaypoints = window._(missions).find({ missionId: selectedMissionId }).waypoints;
      setWaypoints(selectedMissionWaypoints);

      // If there is no waypoint selected, select the first one and update the popup info.
      if (selectedMissionWaypoints.length > 0) {
        setSelectedWaypoint(selectedMissionWaypoints[0]);
        setPopupInfo(selectedMissionWaypoints[0]);
      }
    }
    setCurrentAltitude(40);
  }, [selectedMissionId]);

  useEffect(() => {
    let newWaypoints = waypoints;
    // Check if the waypoints are in the right order.
    if (waypoints.map((waypoint, index) => waypoint.order === index + 1).includes(false)) {
      newWaypoints = newWaypoints.map((waypoint, index) => new Waypoint({ ...waypoint, order: index + 1 }));
      setWaypoints(newWaypoints);
    }

    // Ensure last and first waypoints having some coordinates sync with each other
    if (
      newWaypoints.length > 0 &&
      checkSameCoordinatesValidator(newWaypoints[newWaypoints.length - 1], newWaypoints[0])
    ) {
      const lastWaypointCopy = newWaypoints[newWaypoints.length - 1];
      newWaypoints[newWaypoints.length - 1] = {
        ...lastWaypointCopy,
        ...lastWaypointCopy,
        altitude: newWaypoints[0].altitude,
        heading: newWaypoints[0].heading,
        latitude: newWaypoints[0].latitude,
        longitude: newWaypoints[0].longitude,
      };
    }

    const newMissions = missions.map((mission) => {
      if (mission.missionId === selectedMissionId) return new Mission({ ...mission, waypoints: newWaypoints });
      return mission;
    });

    setMissions(newMissions);
  }, [waypoints]);

  const getCoordinateCenter = () => {
    let minLat = null;
    let maxLat = null;
    let minLong = null;
    let maxLong = null;

    waypoints.forEach((waypoint) => {
      minLat = waypoint.latitude < minLat || minLat == null ? waypoint.latitude : minLat;
      maxLat = waypoint.latitude > maxLat || maxLat == null ? waypoint.latitude : maxLat;
      minLong = waypoint.longitude < minLong || minLong == null ? waypoint.longitude : minLong;
      maxLong = waypoint.longitude > maxLong || maxLong == null ? waypoint.longitude : maxLong;
    });

    if (mapRef.current) {
      mapRef.current.fitBounds(
        [
          [minLong, minLat],
          [maxLong, maxLat],
        ],
        { duration: 1000, maxZoom: 16 }
      );
    }
  };

  const displayReportLog = () => {
    setShowReportLog(missionValidationErrors.length > 0);
    setShowMinimizedReport(false);
  };

  const displayMinimizedReportLog = () => {
    setShowReportLog(false);
    setShowMinimizedReport(true);
  };

  useEffect(() => {
    displayReportLog();
  }, [missionValidationErrors]);

  useEffect(() => {
    if (mapRef.current) {
      navigator.geolocation.getCurrentPosition((pos) => {
        mapRef.current.flyTo({
          center: [pos.coords.longitude, pos.coords.latitude],
          zoom: 16,
          duration: 3000,
          speed: 0.9,
        });
      });
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (editMissionId === 0) {
      if (waypoints.length > 0) {
        getCoordinateCenter();
      }
    }
  }, [waypoints, editMissionId, mapRef.current, selectedMissionId]);

  useEffect(() => {
    const filteredWaypoints = waypoints.filter((wp) => wp.waypointTypeDefinitionId === 0);

    const mappedLineStrings =
      filteredWaypoints.length > 1 ? turf.lineString(filteredWaypoints.map((wp) => [wp.longitude, wp.latitude])) : null;
    setLineStrings(mappedLineStrings);
  }, [waypoints]);

  const markerClickedHandler = (waypoint, e) => {
    e.originalEvent.stopPropagation();
    setSelectedWaypoint(waypoint);
  };

  const waypointAddedHandler = (coordinates) => {
    const routeWaypointsLength = waypoints.filter((wp) => wp.waypointTypeDefinitionId === 0).length;

    if (selectedMissionId !== 0 && editMissionId !== 0) {
      setWaypoints((oldWps) => [
        ...oldWps,
        new Waypoint({
          missionId: selectedMissionId,
          name: null,
          order: routeWaypointsLength + 1,
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          heading: 0,
          altitude: currentAltitude,
          waypointTypeDefinitionId: 0,
        }),
      ]);
    }
  };

  const waypointUpdatedHandler = (orders, newCoordinates) => {
    const newWaypoints = waypoints.map((wp) => {
      if (orders.includes(wp.order)) {
        return new Waypoint({
          ...wp,
          longitude: newCoordinates.longitude,
          latitude: newCoordinates.latitude,
        });
      }

      return wp;
    });

    setWaypoints(newWaypoints);
  };

  const markerDraggedHandler = (order, e) => {
    setSelectedWaypoint(null);
    const maxOrder = Math.max(...waypoints.map((wp) => wp.order));
    const draggedWaypoint = waypoints.find((wp) => wp.order === order);
    const lastWaypoint = waypoints.find((wp) => wp.order === maxOrder);

    if (draggedWaypoint && lastWaypoint) {
      const newCoordinates = {
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
      };

      const ordersToUpdate = checkSameCoordinatesValidator(draggedWaypoint, lastWaypoint)
        ? [order, lastWaypoint.order]
        : [order];

      waypointUpdatedHandler(ordersToUpdate, newCoordinates);
    }
  };

  const findClosestWaypoint = (clickedCoordinates) => {
    let closestWaypoint = null;
    let minDistance = Infinity;

    waypoints.forEach((waypoint) => {
      const distance = turf.distance(
        turf.point([waypoint.longitude, waypoint.latitude]),
        turf.point(clickedCoordinates)
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestWaypoint = waypoint;
      }
    });

    return closestWaypoint;
  };

  const createWaypoint = (order, newCoordinates, altitude) =>
    new Waypoint({
      missionId: selectedMissionId,
      name: null,
      order,
      longitude: newCoordinates[0],
      latitude: newCoordinates[1],
      heading: 0,
      altitude,
      waypointTypeDefinitionId: 0,
    });

  const handleLineStringClick = (coordinates) => {
    const threshold = 0.000001;

    const clickedLineStringPoint = turf.pointOnLine(lineStrings, [coordinates.lng, coordinates.lat]);
    const clickedCoordinates = clickedLineStringPoint.geometry.coordinates;

    // Find the closest waypoint to the clicked point
    const closestWaypoint = findClosestWaypoint(clickedCoordinates);
    const nextToClosestWaypoint = waypoints.find((wp) => wp.order === closestWaypoint.order + 1);

    const clickedLongitude = clickedCoordinates[0];
    const clickedLatitude = clickedCoordinates[1];

    // Calculate the distances between the clicked point and the two waypoints
    const distanceBetweenWaypoints = calculateDistanceBetweenPoints(closestWaypoint, nextToClosestWaypoint);
    const distanceToClosest = calculateDistanceBetweenPoints(
      { longitude: clickedLongitude, latitude: clickedLatitude },
      closestWaypoint
    );
    const distanceToNextClosest = calculateDistanceBetweenPoints(
      { longitude: clickedLongitude, latitude: clickedLatitude },
      nextToClosestWaypoint
    );

    let newOrder = null;
    let newWaypoint = null;
    let newAltitude = null;

    // Check if the clicked point falls within the specified threshold of the two waypoints
    if (Math.abs(distanceBetweenWaypoints - (distanceToClosest + distanceToNextClosest)) < threshold) {
      // If the point falls within the threshold, create a new waypoint after the closest waypoint
      newOrder = closestWaypoint.order + 1;
      newAltitude = closestWaypoint.altitude;
      newWaypoint = createWaypoint(newOrder, clickedCoordinates, newAltitude);
    } else {
      // If the point doesn't fall within the threshold, place it at the end or after the previous waypoint
      // Iterate through the waypoints to find the position where the new waypoint should be inserted
      const newWaypoints = waypoints.slice();
      for (let i = 0; i < newWaypoints.length - 1; i++) {
        const currentWaypoint = newWaypoints[i];
        const nextWaypoint = newWaypoints[i + 1];

        const distanceToFirst = calculateDistanceBetweenPoints(
          { longitude: clickedLongitude, latitude: clickedLatitude },
          currentWaypoint
        );
        const distanceToNext = calculateDistanceBetweenPoints(
          { longitude: clickedLongitude, latitude: clickedLatitude },
          nextWaypoint
        );
        const distanceBetween = calculateDistanceBetweenPoints(currentWaypoint, nextWaypoint);

        // If the clicked point falls within the threshold of the current and next waypoints, insert the new waypoint
        if (Math.abs(distanceBetween - (distanceToFirst + distanceToNext)) < threshold) {
          newOrder =
            closestWaypoint === undefined ? waypoints[waypoints.length - 1].order + 1 : currentWaypoint.order + 1;
          newAltitude =
            closestWaypoint === undefined ? waypoints[waypoints.length - 1].altitude : currentWaypoint.altitude;
          newWaypoint = createWaypoint(newOrder, clickedCoordinates, newAltitude);

          // Break out of the loop since the new waypoint's position has been determined
          break;
        }
      }
    }

    // Find the index where the new waypoint should be inserted based on its order
    const insertIndex = waypoints.findIndex((wp) => wp.order >= newOrder);

    // Increment the order of waypoints after the new waypoint if it's not inserted at the end
    if (insertIndex !== -1) {
      for (let i = insertIndex; i < waypoints.length; i++) {
        waypoints[i].order++;
      }
    }

    // Insert the new waypoint into the correct position in the waypoints array
    waypoints.splice(insertIndex !== -1 ? insertIndex : waypoints.length, 0, newWaypoint);
    setWaypoints(waypoints);
    setSelectedWaypoint(newWaypoint);
  };

  const hasExistingCoordinates = (coordinates) =>
    waypoints.some((wp) => wp.longitude === coordinates.lng && wp.latitude === coordinates.lat);

  const maxOrderLocationSameAsMin = () => {
    const minOrderWaypoint = window._.find(waypoints, { order: 1 });
    const maxOrderWaypoint = window._.find(waypoints, { order: waypoints.length });
    return minOrderWaypoint.sameLocationAs(maxOrderWaypoint);
  };

  const clickHandler = (data) => {
    const newWaypointCoordinates = data.lngLat;

    if (hasExistingCoordinates(newWaypointCoordinates)) {
      window.toast.error('Waypoint with the same coordinates already exists!');
      return;
    }

    if (selectedWaypoint) {
      setSelectedWaypoint(null);
      return;
    }

    if (waypoints.length > 0 && maxOrderLocationSameAsMin()) {
      if (editMissionId) {
        handleLineStringClick(newWaypointCoordinates);
        window.toast.info('You have added a new waypoint. Do not forget to save the mission.');
      }
      return;
    }

    waypointAddedHandler({
      longitude: newWaypointCoordinates.lng,
      latitude: newWaypointCoordinates.lat,
    });
  };

  return (
    <MissionsContext.Provider
      value={{
        missions,
        setMissions,
        missionValidationErrors,
        setMissionValidationErrors,
        editMissionId,
        setEditMissionId,
        selectedMissionId,
        setSelectedMissionId,
        selectedWaypoint,
        setSelectedWaypoint,
        waypoints,
        setWaypoints,
        photoArea,
        setPhotoArea,
        maxWaypointId,
        setMaxWaypointId,
        popupInfo,
        setPopupInfo,
        mapStyle,
        setMapStyle,
        currentAltitude,
        setCurrentAltitude,
        showAltitudeInput,
        setShowAltitudeInput,
        showReportLog,
        showMinimizedReportLog,
        apps,
        favoriteApps,
        selectAppPopup,
        setSelectAppPopup,
        selectedApps,
        setSelectedApps,
      }}
    >
      <PageWrapper>
        <div
          style={{
            height: '100vh',
            width: '100%',
            filter: showReportLog ? 'blur(30px)' : 'none',
            pointerEvents: showReportLog ? 'none' : 'auto',
          }}
        >
          <MissionMap
            waypoints={waypoints}
            drones={drones}
            clickHandler={clickHandler}
            mapRef={mapRef}
            lineStrings={lineStrings}
            mapStyle={mapStyle}
            photoArea={photoArea}
            editable={editMissionId !== 0}
            selectedWaypoint={selectedWaypoint}
            markerClickedHandler={markerClickedHandler}
            markerDraggedHandler={markerDraggedHandler}
          />
        </div>

        <MapOverlay />

        {/* Displaying Report Logs on Mission Validation */}
        <div>
          {showReportLog && (
            <div className="map-overlay-report-log-center">
              <ReportLog
                validationErrors={missionValidationErrors}
                showEditMissionButton
                displayMinimizedReportLog={displayMinimizedReportLog}
              />
            </div>
          )}
          {showMinimizedReportLog && (
            <div className="map-overlay-report-log-top-right">
              <MinimizedReportLog errors={missionValidationErrors} />
            </div>
          )}
        </div>
      </PageWrapper>
    </MissionsContext.Provider>
  );
}
