import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function ErrorList({ errors }) {
  if (errors.length === 0) {
    return (
      <div className="relative flex flex-col my-0 lg:my-10 bg-white max-w-[700px] mx-auto rounded-xl bg-clip-border">
        <nav className="flex max-w-[700px] max-h-[550px] flex-col gap-1 font-sans text-base font-normal text-blue-gray-700">
          <div className="flex items-center mt-2 mb-2 space-y-4">
            <div className="w-full bg-[#D0FFBC] rounded p-4 relative">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faCheckCircle} className="text-green" />
                <h4 className="text-green font-semibold">All checks passed</h4>
              </div>
              <p className="ml-6">Checks in this section are safe to fly</p>
            </div>
          </div>
        </nav>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col my-0 lg:my-10 max-w-[700px] mx-auto rounded-xl bg-clip-border">
      <nav className="flex max-w-[700px] max-h-[550px] flex-col gap-1 font-sans text-base font-normal text-blue-gray-700">
        {errors.map((error) => (
          <div key={error} className="flex items-center mt-2 mb-2 space-y-4">
            <div className="w-full bg-[#fff1f2] rounded p-4 relative">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faTimesCircle} className="text-red" />
                <h4 className="text-red font-semibold">{error.title}</h4>
              </div>
              <p className="ml-6">{error.description}</p>
            </div>
          </div>
        ))}
      </nav>
    </div>
  );
}
