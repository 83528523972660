import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export function StepperButtons({ nextCheckHandler, previousCheckHandler, steps, mobile }) {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    const newState = currentStep >= steps.length ? steps.length + 1 : currentStep + 1;
    setCurrentStep(newState); // len + 1 => go to software checks
    nextCheckHandler(newState);
  };

  const previousStep = () => {
    const newState = currentStep <= 1 ? 0 : currentStep - 1;
    setCurrentStep(newState); // 0 => go to
    previousCheckHandler(newState);
  };

  return (
    <div className="items-center">
      <div
        className={`flex justify-center items-end my-9 ${
          mobile ? 'visible rc:invisible pl-12' : 'invisible rc:visible'
        }`}
      >
        <button
          type="button"
          onClick={previousStep}
          className="bg-light-blue text-blue w-40 p-2 px-16 rounded-xl mx-2 flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Back
        </button>
        <button
          type="button"
          onClick={nextStep}
          className="bg-blue text-white w-40 p-2 px-16 rounded-xl mx-2 flex items-center justify-center"
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </button>
      </div>
    </div>
  );
}

export default StepperButtons;
