import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import ErrorList from '../ErrorList/ErrorList';
import GenericService from '../../services/generic.service';
import { FlightReport } from '../../classes/FlightReport';

export default function DroneAutomaticCheck({ checkName, checkDescription, isLoading, isSuccessful, errors }) {
  const navigate = useNavigate();
  const droneId = localStorage.getItem('droneId');
  const missionId = localStorage.getItem('missionId');

  const [speed, setSpeed] = useState(4);
  const [photoDelayMs, setPhotoDelayMs] = useState(1600);
  const [gimbalRotation, setGimbalRotation] = useState(20);
  const [gimbalPitch, setGimbalPitch] = useState(30);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const handleSpeedChange = (e) => setSpeed(parseFloat(e.target.value));
  const handlePhotoDelayMsChange = (e) => setPhotoDelayMs(parseFloat(e.target.value));
  const handleGimbalRotationChange = (e) => setGimbalRotation(parseFloat(e.target.value));
  const handleGimbalPitchChange = (e) => setGimbalPitch(parseFloat(e.target.value));
  const toggleAdvancedSettings = () => setShowAdvancedSettings(!showAdvancedSettings);

  const onCancelClick = () => {
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'cancel_take_off');
    navigate('/schedule');
  };

  const onStartClick = () => {
    // Create flight report
    const newFlightReport = new FlightReport();
    newFlightReport.droneId = droneId;
    newFlightReport.missionId = missionId;
    newFlightReport.executeImmediate = true;
    newFlightReport.executeAt = new Date().toISOString();

    GenericService.FlightReportService.saveFlightReport(newFlightReport)
      .then((res) => {
        console.log('Flight Report Save Response:', res);
        // Launch drone
        GenericService.FlightReportService.sendDroneCommand(
          droneId,
          res.flightReportId,
          'take_off',
          speed,
          photoDelayMs,
          gimbalRotation,
          gimbalPitch
        );
        // Navigate to the dashboard with the assigned drone
        navigate(`/?droneId=${droneId}`);
      })
      .catch((error) => {
        console.error('Error saving flight report:', error);
      });
  };

  if (isLoading) {
    return (
      <div className="container max-w-3xl">
        <Loader />
        <h1 className="text-3xl font-bold mb-5 mt-10">{checkName}</h1>
        <p className="text-lg mb-10 color-grey">{checkDescription}</p>
        <Button text="CANCEL" buttonType="cancel" />
      </div>
    );
  }
  return (
    <div className="flex">
      <div className="w-full flex flex-col justify-center">
        <div>
          {isSuccessful ? (
            <div className="container max-w-3xl">
              <h3 className="font-bold text-3xl mb-5">All checks Finished!</h3>
              <p className="text-lg mb-5 color-grey">
                Ready to take off? When you click &quot;GO&quot;, the drone will take off and start the mission.
              </p>
              <div className="mb-5">
                <button
                  type="button"
                  className="bg-[#f0f0f0] w-full mb-1 rounded-md"
                  onClick={() => toggleAdvancedSettings()}
                >
                  {showAdvancedSettings ? 'Hide' : 'Show'} Advanced Settings
                  <FontAwesomeIcon icon={showAdvancedSettings ? faChevronUp : faChevronDown} className="ml-2" />
                </button>
                {showAdvancedSettings && (
                  <div>
                    <div className="relative z-0 w-full mb-6 group">
                      <label htmlFor="speed">
                        Speed (m/s)
                        <input
                          type="number"
                          name="speed"
                          id="speed"
                          value={speed}
                          onChange={handleSpeedChange}
                          placeholder=" "
                        />
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <label htmlFor="photoDelayMs">
                        Photo Delay (ms)
                        <input
                          type="number"
                          name="photoDelayMs"
                          id="photoDelayMs"
                          value={photoDelayMs}
                          onChange={handlePhotoDelayMsChange}
                          placeholder=" "
                        />
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <label htmlFor="gimbalRotation">
                        Gimbal Rotation (°)
                        <input
                          type="number"
                          name="gimbalRotation"
                          id="gimbalRotation"
                          value={gimbalRotation}
                          onChange={handleGimbalRotationChange}
                        />
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <label htmlFor="gimbalPitch">
                        Gimbal Pitch (°)
                        <input
                          type="number"
                          name="gimbalPitch"
                          id="gimbalPitch"
                          value={gimbalPitch}
                          onChange={handleGimbalPitchChange}
                          placeholder=" "
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex gap-4">
                <Button text="CANCEL" buttonType="cancel" onClick={onCancelClick} />
                <Button text="GO" buttonType="start" onClick={onStartClick} />
              </div>
              <p className="mt-10 text-red text-lg">
                <span className="text-lg text-red font-bold">Attention: </span>Keep a safe distance now!
              </p>
            </div>
          ) : (
            <ErrorList errors={errors} />
          )}
        </div>
      </div>
    </div>
  );
}
