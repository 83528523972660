import React from 'react';
import mapBoxStyleLight from '../../assets/images/map/ic_default-2x.png';
import mapBoxStyleSatellite from '../../assets/images/map/ic_satellite-2x.png';
import mapBoxStyleZones from '../../assets/images/map/ic_flyzones-2x.png';
import mapBoxStyleAllZones from '../../assets/images/map/ic_flyzones_all-2x.png';

export default function MapStyleSwitch({ setMapStyle }) {
  return (
    <div className="map-overlay-right-bottom" style={{ display: 'flex' }}>
      <button
        type="button"
        className="button map-layer"
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        onClick={() => setMapStyle('streets-v11')}
      >
        <img src={mapBoxStyleLight} alt="Map style light" />
      </button>

      <button
        type="button"
        className="button map-layer"
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={() => setMapStyle('satellite-v9')}
      >
        <img src={mapBoxStyleSatellite} alt="Map style satellite" />
      </button>

      <button
        type="button"
        className="button map-layer"
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={() => setMapStyle('nofly-zones')}
      >
        <img src={mapBoxStyleZones} alt="No-fly zones" />
      </button>

      <button
        type="button"
        className="button map-layer"
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={() => setMapStyle('nofly-zones-all')}
      >
        <img src={mapBoxStyleAllZones} alt="No-fly zones all" />
      </button>
    </div>
  );
}
