import React from 'react';
import DroneCard from '../../components/Card/DroneCard/DroneCard';

export default function DroneSelection({ drones, selectedDrone, onDroneSelect, maxHeightClass }) { // eslint-disable-line
  const sortByName = (unorderedDrones) => unorderedDrones.sort((d1, d2) => d1.name.localeCompare(d2.name));
  const sortedDrones = sortByName(drones);

  return (
    <div className={`overflow-y-auto flex flex-col space-y-4 rounded-lg pb-1 pr-2 ${maxHeightClass}`}>
      {sortedDrones.map((drone) => (
        <div
          key={drone.droneId}
          role="none"
          className="cursor-pointer"
          onClick={() => {
            onDroneSelect(drone);
            console.log('drone selected :>');
          }}
        >
          <DroneCard drone={drone} isSelected={selectedDrone && drone.droneId === selectedDrone.droneId} />
        </div>
      ))}
    </div>
  );
}
