/* eslint-disable class-methods-use-this */
class DroneCheckService {
  async getSoftwareChecks(telemetryData) {
    if (!telemetryData) {
      return {
        isDroneConnected: false,
        isBatteryLevelEnough: false,
        isInternetConnectionLive: false,
        areChecksPending: false,
        areHealthChecksFailing: true,
        droneErrors: [{ title: 'Connection failed', description: 'There is no drone connected' }],
      };
    }

    try {
      const isDroneConnected = this.isDroneConnected(telemetryData);
      const isBatteryLevelEnough = this.isBatteryLevelEnough(telemetryData);
      const isInternetConnectionLive = this.isInternetConnectionLive(telemetryData);
      const areChecksPending = this.areChecksPending(telemetryData);
      const areHealthChecksFailing = this.areHealthChecksFailing(telemetryData);
      const droneErrors = this.getErrorMessages(
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areHealthChecksFailing,
        telemetryData
      );

      return {
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areChecksPending,
        areHealthChecksFailing,
        droneErrors,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  isDroneConnected(telemetryData) {
    return telemetryData != null;
  }

  isBatteryLevelEnough(telemetryData) {
    return telemetryData.battery[0].remaining_percent > 20;
  }

  isInternetConnectionLive(telemetryData) {
    return telemetryData.status[0].internet_connected;
  }

  areChecksPending(telemetryData) {
    const healthChecks = telemetryData.health[0];

    return Object.values(healthChecks).some((category) => category.some((check) => check.pending));
  }

  areHealthChecksFailing(telemetryData) {
    const healthChecks = telemetryData.health[0];

    return Object.values(healthChecks).some((category) => category.some((check) => !check.status));
  }

  getHealthChecksErrorMessages(telemetryData) {
    const healthChecks = telemetryData.health[0];
    const messages = [];

    Object.keys(healthChecks).forEach((key) => {
      healthChecks[key].forEach((entry) => {
        if (entry.message) {
          messages.push({ title: key, description: entry.message });
        }
      });
    });

    return messages;
  }

  getErrorMessages(
    isDroneConnected,
    isBatteryLevelSufficient,
    isInternetConnectionStable,
    areHealthChecksFailing,
    telemetryData
  ) {
    if (!isDroneConnected) {
      return [{ title: 'Connection failed', description: 'There is no drone connected' }];
    }
    const errors = [];
    if (!isBatteryLevelSufficient) {
      errors.push({
        title: 'Insufficient Battery Level',
        description: 'The battery level is too low for safe operation',
      });
    }
    if (!isInternetConnectionStable) {
      errors.push({
        title: 'Unstable Internet Connection',
        description: 'No or unstable Internet connection',
      });
    }
    if (areHealthChecksFailing) {
      const healthErrors = this.getHealthChecksErrorMessages(telemetryData);
      errors.push(...healthErrors);
    }

    return errors;
  }
}

export default DroneCheckService;
