import { calculateDistanceBetweenPoints, calculateTotalDistance } from './waypointDistanceHelper';
import checkSameCoordinatesValidator from './checkSameCoordinatesValidator';

export const validateMissionSafetyWarnings = (mission) => {
  const missionSafetyWarnings = [];

  // Altitude warning
  const altitudes = mission.waypoints.map((waypoint) => waypoint.altitude);
  const altitudesDiffer = altitudes.some((altitude, index) => altitude !== altitudes[0] && index !== 0);

  if (altitudesDiffer) {
    const warning = {
      type: 'warning',
      header: 'WARN: Your mission has locations with different altitudes.',
      message: 'Ensure consistent altitudes for safer operations.',
    };
    missionSafetyWarnings.push(warning);
  }

  return missionSafetyWarnings;
};

export const validateMissionSafetyErrors = (mission) => {
  const missionSafetyErrors = [];

  // Name validation
  if (!mission.name) {
    const error = {
      type: 'error',
      header: 'Invalid name.',
      message: 'Mission must have a name.',
    };
    missionSafetyErrors.push(error);
  }

  // Waypoints validation
  if (mission.waypoints.length < 2) {
    const error = {
      type: 'error',
      header: 'Not enough waypoints.',
      message: 'Mission must have at least 2 waypoints.',
    };
    missionSafetyErrors.push(error);
  }

  // Altitude validation
  mission.waypoints.forEach((waypoint) => {
    if (waypoint.altitude < 0) {
      const error = {
        type: 'error',
        header: `Waypoint ${waypoint.order} altitude error.`,
        message: `Altitude of waypoint ${waypoint.order} should be greater than 0m.`,
      };
      missionSafetyErrors.push(error);
    } else if (waypoint.altitude > 120) {
      const error = {
        type: 'error',
        header: `Waypoint ${waypoint.order} altitude error.`,
        message: `Altitude of waypoint ${waypoint.order} should be lower than 120m.`,
      };
      missionSafetyErrors.push(error);
    }
  });

  // Distance between consecutive waypoints validation
  for (let i = 1; i < mission.waypoints.length; i++) {
    const distanceBetweenWaypoints = calculateDistanceBetweenPoints(mission.waypoints[i - 1], mission.waypoints[i]);
    if (distanceBetweenWaypoints * 1000 > 800) {
      const error = {
        type: 'error',
        header: `Waypoint ${mission.waypoints[i - 1].order} and ${mission.waypoints[i].order} are too far.`,
        message: `Distance between waypoints ${mission.waypoints[i - 1].order} and ${
          mission.waypoints[i].order
        } is longer than 800m, please add more waypoints or move them closer.`,
      };
      missionSafetyErrors.push(error);
    }
  }

  // Mission duration validation
  const flightSpeed = 1.5;
  const totalMissionDistance = calculateTotalDistance(mission.waypoints);
  const totalMissionTime = (totalMissionDistance * 1000) / flightSpeed;
  if (false && totalMissionTime > 2700) {
    const error = {
      type: 'error',
      header: 'Mission duration too long.',
      message: `Approximate mission duration exceeds 45 minutes. Please shorten the mission.`,
    };
    missionSafetyErrors.push(error);
  }

  // Mission starting and ending at home location validation
  const homeWaypoint = mission.waypoints.find((waypoint) => waypoint.order === 1);
  const endWaypoint = mission.waypoints.find((waypoint) => waypoint.order === mission.waypoints.length);
  if (!checkSameCoordinatesValidator(homeWaypoint, endWaypoint)) {
    const error = {
      type: 'error',
      header: 'Home location mismatch.',
      message: 'The mission must end at the home location. Please, add the home location.',
    };
    missionSafetyErrors.push(error);
  }

  return missionSafetyErrors;
};
