import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Custom StepIcon component
const CustomStepIcon = styled((props) => {
  if (props.error) {
    return <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />;
  }
  return <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'gray' }} />;
})(() => ({
  fontSize: '1.2rem',
}));

export default function HorizontalStepper({ steps, widthPercentage, stepsTitle }) {
  const [activeStep, setActiveStep] = useState(0);

  // This effect updates the active step if the steps change and the current step is no longer pending
  useEffect(() => {
    if (!steps[activeStep][2]) {
      // If the current step is not pending
      const nextStep = steps.findIndex((step, index) => index > activeStep && step[2]);
      if (nextStep !== -1) {
        setActiveStep(nextStep); // Set to the next pending step
      } else {
        setActiveStep(steps.length - 1); // Or set to the last step if no more pending steps
      }
    }
  }, [steps, activeStep]);

  return (
    <Box sx={{ width: `${widthPercentage}%` }}>
      <h4 className="text-grey mb-3">{stepsTitle}</h4>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          if (!step[1] && step[1] != null) {
            // If the step has failed
            labelProps.error = true;
          }

          return (
            <Step key={step[0]} completed={index < activeStep || (index === activeStep && !steps[activeStep][2])}>
              <StepLabel StepIconComponent={CustomStepIcon} error={labelProps.error}>
                {step[0]}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
