import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { StepperButtons } from './StepperButtons';

export function Stepper({ nextCheckHandler, previousCheckHandler, steps }) {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = (step) => {
    setCurrentStep(step);
    nextCheckHandler(step);
  };

  const previousStep = (step) => {
    setCurrentStep(step);
    previousCheckHandler(step);
  };

  return (
    <div className="invisible rc:visible flex flex-col justify-between bg-white w-1/5 px-12 pt-12 h-screen fixed right-0">
      <div className="flex-grow">
        <h3>Physical Preparation</h3>
        <div className="flex justify-between flex-col mt-6">
          {steps?.map((step, i) => (
            <div
              key={step}
              className={`relative flex flex-row pb-12 items-center step-item ${
                currentStep === i + 1 ? 'active' : ''
              } ${i + 1 < currentStep ? 'complete' : ''}`}
            >
              <div className="h-6 w-6 mr-6 flex items-center justify-center z-10 relative bg-white">
                {currentStep === i + 1 ? (
                  <FontAwesomeIcon icon={faDotCircle} className="w-10 h-10 text-blue" />
                ) : (
                  <FontAwesomeIcon
                    icon={i + 1 < currentStep ? faCircleCheck : faCircle}
                    className="w-10 h-10 text-gray-700"
                  />
                )}
              </div>

              <div className="ml-6">
                <p className={`text-xl ${currentStep === i + 1 ? 'text-blue font-medium' : 'text-grey400'}`}>
                  {step[0]}
                </p>
              </div>

              {/* Additional div for the step connector line if it's not the last child */}
              {i + 1 < steps.length && (
                <div className="block border border-lightGray bg-blue absolute w-0.5 h-full top-1/4 ml-[11px] before:content-['']" />
              )}
            </div>
          ))}
        </div>
      </div>
      <StepperButtons nextCheckHandler={nextStep} previousCheckHandler={previousStep} steps={steps} mobile={false} />
    </div>
  );
}
export default Stepper;
