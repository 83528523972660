import { HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState, useRef } from 'react';

const useSignalRConnection = (droneId) => {
  const [connection, setConnection] = useState(null);
  const [debugData, setDebugData] = useState(null);
  const [telemetryData, setTelemetryData] = useState(null);
  const telemetryTimeoutRef = useRef(null);

  const debugDataReceivedHandler = (data) => {
    setDebugData(data);
  };

  const telemetryDataReceivedHandler = (data) => {
    const parsedData = JSON.parse(data);
    setTelemetryData(parsedData);

    // Clear the previous timeout and set a new one
    if (telemetryTimeoutRef.current) {
      clearTimeout(telemetryTimeoutRef.current);
    }
    telemetryTimeoutRef.current = setTimeout(() => {
      setTelemetryData(null);
    }, 2000); // Set the drone to offline if no telemetry data is received within 5 seconds
  };

  const closeConnection = async () => {
    if (connection) {
      await connection.stop();
    }
  };

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/MRRHub?droneId=${droneId}`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    return () => {
      closeConnection();
    };
  }, [droneId]);

  useEffect(() => {
    async function getData() {
      if (connection) {
        await connection.start();
        connection.on('DebugDataReceived', debugDataReceivedHandler);
        connection.on('TelemetryDataReceived', telemetryDataReceivedHandler);
      }
    }
    getData();
  }, [connection]);

  return { connection, debugData, telemetryData, closeConnection };
};

export default useSignalRConnection;
