import React, { useContext } from 'react';
import { MissionsContext } from '../../../pages/Missions/Missions';
import './MissionAppCard.scss';

export default function MissionAppCard({ app }) {
  const { setSelectedApps, selectedApps } = useContext(MissionsContext);

  const handleClick = () => {
    if (selectedApps.find((selected) => selected.id === app.id)) {
      setSelectedApps(selectedApps.filter((selected) => selected.id !== app.id));
    } else {
      setSelectedApps(selectedApps.concat(app));
    }
  };

  const isSelected = selectedApps.some((selectedApp) => selectedApp.id === app.id);

  return (
    <button
      type="button"
      key={app.id}
      onClick={() => handleClick()}
      className={`app-store-card ${isSelected ? 'selected' : ''} mt-5`}
    >
      <img src={app.logoUrl} alt={app.name} className="app-logo float-left mr-3" />
      <div className="text-left">
        <span className="font-bold">{app.name}</span>
        <p className="app-description">{app.description}</p>
      </div>
    </button>
  );
}
