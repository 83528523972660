import axios from 'axios';

class WeatherService {
  static WIND_SPEED_THRESHOLD = 10; // m/s

  static RAIN_THRESHOLD = 0; // mm/h

  constructor() {
    this.API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
  }

  /**
   * Gets current weather conditions and checks if they're suitable for flying
   * @returns {Promise<Object>} Weather conditions and any errors
   */
  async getCurrentWeather() {
    try {
      const position = await WeatherService.getCurrentPosition();
      const weatherInfo = await this.getWeatherInfo(position.coords);
      const isWindy = !WeatherService.isWindConditionSuitable(weatherInfo.wind.speed);
      const isDay = WeatherService.isSunUp(weatherInfo.sys.sunrise, weatherInfo.sys.sunset);
      const isRainy = !WeatherService.isRainConditionSuitable(weatherInfo?.rain?.['1h']);
      const weatherErrors = WeatherService.getWeatherErrors(isRainy, isWindy, isDay);

      return {
        isRainy,
        isWindy,
        isDay,
        weatherErrors,
      };
    } catch (error) {
      // TODO: Sometimes this occurs, "geolocation update is not available", after few minutes disappears
      console.error('Weather service error:', error);
      return null;
    }
  }

  /**
   * Gets the current geolocation position
   * @returns {Promise} Geolocation position
   */
  static getCurrentPosition() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          { timeout: 2000 } // Timeout after 2 seconds
        );
      } else {
        reject(new Error('Geolocation is not available on this device.'));
      }
    });
  }

  /**
   * Fetches weather information from OpenWeather API
   * @param {Object} coords - Coordinates object with latitude and longitude
   * @returns {Promise<Object>} Weather information
   */
  async getWeatherInfo(coords) {
    if (!this.API_KEY) {
      throw new Error('OpenWeather API key is not configured');
    }

    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&appid=${this.API_KEY}`;
    const response = await axios.get(url);
    return response.data;
  }

  /**
   * Checks if wind conditions are suitable for flying
   * @param {number} windSpeed - Wind speed in m/s
   * @returns {boolean} Whether wind conditions are suitable
   */
  static isWindConditionSuitable(windSpeed) {
    return windSpeed <= WeatherService.WIND_SPEED_THRESHOLD;
  }

  /**
   * Checks if rain conditions are suitable for flying
   * @param {number} rainAmount - Rain amount in mm/h
   * @returns {boolean} Whether rain conditions are suitable
   */
  static isRainConditionSuitable(rainAmount) {
    return !rainAmount || rainAmount <= WeatherService.RAIN_THRESHOLD;
  }

  /**
   * Checks if current time is during daylight hours
   * @param {number} sunrise - Sunrise time in Unix timestamp
   * @param {number} sunset - Sunset time in Unix timestamp
   * @returns {boolean} Whether it's currently daytime
   */
  static isSunUp(sunrise, sunset) {
    const currentTime = Date.now() / 1000;
    return currentTime >= sunrise && currentTime <= sunset;
  }

  /**
   * Generates array of weather-related errors
   * @param {boolean} isLightRain - Whether rain conditions are suitable
   * @param {boolean} isLightWind - Whether wind conditions are suitable
   * @param {boolean} isDay - Whether it's currently daytime
   * @returns {Array<string>} Array of weather error messages
   */
  static getWeatherErrors(isRainy, isWindy, isDay) {
    const errors = [];
    if (isRainy) {
      errors.push({ title: 'Expecting rain', description: 'The drone is not water resistant' });
    }
    if (isWindy) {
      errors.push({
        title: 'Wind is too strong',
        description: `Wind speed is above ${this.WIND_SPEED_THRESHOLD} m/s`,
      });
    }
    if (!isDay) {
      errors.push({ title: 'Visibility is too low', description: 'It is not safe to fly at night time' });
    }
    return errors;
  }
}

export default WeatherService;
