import React, { useContext } from 'react';
import MissionBar from './MissionBar/MissionBar';
import MapStyleSwitch from '../../../components/MapStyleSwitch/MapStyleSwitch';
import { MissionsContext } from '../Missions';

export default function MapOverlay() {
  const { mapStyle, setMapStyle, showReportLog } = useContext(MissionsContext);

  const blurredClass = showReportLog ? 'map-overlay-blurred' : '';

  return (
    <div className={`${blurredClass}`}>
      <div className="map-overlay-left">
        <span className="heading mb-12">
          <h2 className={`${mapStyle === 'satellite-v9' ? 'text-white' : 'text-black'}`}>Missions</h2>
        </span>

        <MissionBar />
      </div>

      <MapStyleSwitch setMapStyle={setMapStyle} />
    </div>
  );
}
