import React from 'react';
import flightCheck from '../../assets/images/pre-flight-check.png';
import safeArea from '../../assets/images/instructions/safe-area.jpg';
import prepareDrone from '../../assets/images/instructions/prepare-drone.png';
import powerOn from '../../assets/images/instructions/power-on.gif';
import moveBack from '../../assets/images/instructions/move-back.jpg';
import checkDrone from '../../assets/images/instructions/check-drone.png';

/**
 * This component renders the Manual Inspection element
 * which displays the required actions that need to be
 * performed by the user before launching the drone
 *
 */

export default function StepperCheckManual({ stepTitle, stepDescription, imageName }) {
  const images = {
    'pre-flight-check': flightCheck,
    'safe-area': safeArea,
    'prepare-drone': prepareDrone,
    'power-on': powerOn,
    'move-back': moveBack,
    'check-drone': checkDrone,
  };

  return (
    <div className="w-full rc:w-4/5 bg-lightGray px-4 md:px-16 pt-5 ml-0 md:ml-5">
      <h2>Manual Inspection</h2>
      <div className="flex flex-col h-550 mt-8">
        <img className="max-h-52 lg:max-h-[25rem] object-cover rounded-xl" alt="flightcheck" src={images[imageName]} />
      </div>
      <div className="lg:mt-6">
        <h3>{stepTitle}</h3>
        <div dangerouslySetInnerHTML={{ __html: stepDescription }} />
      </div>
    </div>
  );
}
