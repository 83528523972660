import React, { useEffect, useState } from 'react';
import './Map.scss';
import mapboxgl from 'mapbox-gl';
import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  Marker,
} from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import proj4 from 'proj4';
import WaypointPopup from '../../pages/Missions/WaypointPopup/WaypointPopup';
import DroneSource from './DashboardMap/DroneSource';
import checkSameCoordinatesValidator from '../../helpers/checkSameCoordinatesValidator';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Define the EPSG:28992 projection
const RDNew = 'EPSG:28992';
const WGS84 = 'EPSG:4326';

const initialViewState = {
  longitude: 4.55,
  latitude: 52.23,
  zoom: 6.5,
};

export default function MissionMap({
  clickHandler,
  mapRef,
  lineStrings,
  mapStyle,
  waypoints,
  editable,
  drones,
  selectedWaypoint,
  markerClickedHandler,
  markerDraggedHandler,
}) {
  const [geoData, setGeoData] = useState(null);
  const [geoDataNoNatura2000, setGeoDataNoNatura2000] = useState(null);

  // Function to filter features based on mapStyle
  const filterFeatures = (data, onlyRedZones) => {
    if (!data) return null;

    return {
      ...data,
      features: data.features.filter((feature) => {
        if (onlyRedZones) {
          // Only show features that are 'Verboden' type AND contain 'radio' in sourceTxt
          return (
            feature.properties.localtype === 'Verboden' && feature.properties.sourceTxt.toLowerCase().includes('radio')
          );
        }
        return true; // Show all features when onlyRedZones is false
      }),
    };
  };

  // Fetch and reproject GeoJSON data on component mount
  useEffect(() => {
    if (mapStyle !== 'nofly-zones' && mapStyle !== 'nofly-zones-all') return;
    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoData(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data: ', err));

    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden_zonder_natura2000&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoDataNoNatura2000(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data (zonder Natura 2000): ', err));
  }, [mapStyle]);

  const mapboxStyle =
    mapStyle === 'nofly-zones-all' || mapStyle === 'nofly-zones'
      ? 'mapbox://styles/mapbox/streets-v11'
      : `mapbox://styles/mapbox/${mapStyle}`;

  const renderMarkerIcon = (wp) => {
    if (selectedWaypoint?.order === wp.order) {
      return (
        <FontAwesomeIcon
          className="fa-2x map-waypoint fa-rotate-by"
          style={{ '--fa-rotate-angle': `${wp.heading}deg` }}
          icon={solid('chevron-up')}
        />
      );
    }
    if (wp.order === 1) {
      return <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('home')} />;
    }
    if (
      waypoints.length > 1 &&
      wp.order === waypoints.length &&
      wp.longitude === waypoints[0].longitude &&
      wp.latitude === waypoints[0].latitude
    ) {
      return ' ';
    }
    return <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('map-marker-alt')} />;
  };

  const markers = () =>
    waypoints &&
    waypoints.length > 0 &&
    waypoints
      .filter((wp) => wp.waypointTypeDefinitionId === 0)
      .map(
        (wp) =>
          !checkSameCoordinatesValidator(wp, waypoints[0]) && (
            <Marker
              id={wp.order}
              key={`marker-${wp.order}`}
              draggable={editable}
              longitude={wp.longitude}
              latitude={wp.latitude}
              anchor="bottom"
              onClick={(e) => markerClickedHandler(wp, e)}
              onDrag={(e) => markerDraggedHandler(wp.order, e)}
            >
              {renderMarkerIcon(wp)}
            </Marker>
          )
      );

  const photoArea = () => {
    const filteredWaypoints = waypoints.filter((waypoint) => waypoint.waypointTypeDefinitionId === 1);
    if (filteredWaypoints.length > 0) {
      const coordinates = filteredWaypoints.map((waypoint) => [waypoint.longitude, waypoint.latitude]);
      coordinates.push([waypoints[0].longitude, waypoints[0].latitude]);

      const geoJson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };

      return (
        <Source id="polygon-source" type="geojson" data={geoJson}>
          <Layer
            id="polygon-layer"
            type="fill"
            beforeId="drone"
            paint={{
              'fill-color': '#0000FF',
              'fill-opacity': 0.1,
            }}
          />
        </Source>
      );
    }
    return null;
  };

  // Filter the data based on mapStyle
  const filteredGeoData = filterFeatures(geoData, mapStyle === 'nofly-zones');
  const filteredGeoDataNoNatura2000 = filterFeatures(geoDataNoNatura2000, mapStyle === 'nofly-zones');

  return (
    <>
      <MapGL
        initialViewState={initialViewState}
        ref={mapRef}
        mapStyle={mapboxStyle}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onClick={clickHandler}
      >
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {photoArea()}
        {markers()}

        {waypoints &&
          waypoints
            .filter((wp) => wp.order === 1 && wp.waypointTypeDefinitionId === 0)
            .map((wp) => (
              <Source
                key={`circle-source-${wp.order}`}
                id={`circle-source-${wp.order}`}
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'Point',
                        coordinates: [wp.longitude, wp.latitude],
                      },
                    },
                  ],
                }}
              >
                <Layer
                  key={`circle-layer-${wp.order}`}
                  id={`circle-layer-${wp.order}`}
                  type="circle"
                  paint={{
                    'circle-radius': ['interpolate', ['exponential', 5], ['zoom'], 0, 0, 20, 300],
                    'circle-color': 'lightblue',
                    'circle-opacity': 0.6,
                  }}
                />
              </Source>
            ))}

        {selectedWaypoint && <WaypointPopup />}

        {lineStrings != null && (
          <Source id="my-data" type="geojson" data={lineStrings}>
            <Layer
              id="drone"
              type="line"
              paint={{
                'line-color': '#acacac',
                'line-width': 3,
              }}
            />
          </Source>
        )}

        {drones != null &&
          drones.map((drone) => (
            <DroneSource key={drone.droneId} drone={drone} selectedDrone={drone} onDroneFocusing={null} />
          ))}

        {/* Render no-fly zone layers when data is available */}
        {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoData && (
          <Source type="geojson" data={filteredGeoData}>
            <Layer
              id="drone-zones"
              type="fill"
              paint={{
                'fill-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders"
              type="line"
              paint={{
                'line-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'line-width': 2,
              }}
            />
          </Source>
        )}

        {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoDataNoNatura2000 && (
          <Source type="geojson" data={filteredGeoDataNoNatura2000}>
            <Layer
              id="drone-zones-no-natura2000"
              type="fill"
              paint={{
                'fill-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders-no-natura2000"
              type="line"
              paint={{
                'line-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'line-width': 2,
              }}
            />
          </Source>
        )}
      </MapGL>
      <div className="overlay" />
    </>
  );
}
