import mapboxgl from 'mapbox-gl';
import proj4 from 'proj4';
import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
} from 'react-map-gl';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DroneSource from './DroneSource';

// The following is required to stop "npm build" from transpiling mapbox code.
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialViewState = {
  longitude: 4.55,
  latitude: 52.23,
  zoom: 6.5,
};

// Define the EPSG:28992 projection
const RDNew = 'EPSG:28992';
const WGS84 = 'EPSG:4326';

// proj4 definition for EPSG:28992 (Amersfoort / RD New)
proj4.defs(
  RDNew,
  '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,-0.350732676542563,1.87034738360664,4.0812 +units=m +no_defs'
);

export default function DashboardMap({ mapStyle, drones, selectedDrone }) {
  const mapRef = useRef(null);
  const [geoData, setGeoData] = useState(null);
  const [geoDataNoNatura2000, setGeoDataNoNatura2000] = useState(null);
  const [localSelectedDrone, setLocalSelectedDrone] = useState(selectedDrone);
  // Extract droneId from URL and update selectedDrone
  const location = useLocation();

  // Function to fly to a specific point on the map
  const flyTo = (longitude, latitude, isZoomIn) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        speed: 0.9,
        zoom: isZoomIn ? 16 : 3.5,
        duration: 3000,
      });
    }
  };

  const droneFocusHandler = (longitude, latitude, isZoomIn) => {
    if (!longitude || !latitude) return;
    flyTo(longitude, latitude, isZoomIn);
  };

  // Function to filter features based on mapStyle
  const filterFeatures = (data, onlyRedZones) => {
    if (!data) return null;

    return {
      ...data,
      features: data.features.filter((feature) => {
        if (onlyRedZones) {
          // Only show features that are 'Verboden' type AND contain 'radio' in sourceTxt
          return (
            feature.properties.localtype === 'Verboden' && feature.properties.sourceTxt.toLowerCase().includes('radio')
          );
        }
        return true; // Show all features when onlyRedZones is false
      }),
    };
  };

  // Extract droneId from URL and update selectedDrone
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const droneId = params.get('droneId');
    if (droneId) {
      setLocalSelectedDrone(droneId);
    }
  }, [location.search]);

  // Memoize drone sources to trigger re-render when localSelectedDrone changes
  const memoizedDroneSources = useMemo(
    () =>
      drones.map((drone) => (
        <DroneSource
          key={`${drone.droneId}-${localSelectedDrone}`}
          drone={drone}
          selectedDrone={localSelectedDrone}
          onDroneFocusing={droneFocusHandler}
        />
      )),
    [drones, localSelectedDrone, droneFocusHandler]
  );

  // Fetch and reproject GeoJSON data on component mount
  useEffect(() => {
    if (mapStyle !== 'nofly-zones' && mapStyle !== 'nofly-zones-all') return;
    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoData(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data: ', err));

    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden_zonder_natura2000&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoDataNoNatura2000(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data (zonder Natura 2000): ', err));
  }, [mapStyle]);

  // Trigger flyTo on component mount
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { longitude, latitude } = pos.coords;
      flyTo(longitude, latitude, true);
    });
  }, []);

  const mapStyleURL =
    mapStyle === 'nofly-zones-all' || mapStyle === 'nofly-zones'
      ? 'mapbox://styles/mapbox/streets-v11'
      : `mapbox://styles/mapbox/${mapStyle}`;

  // Filter the data based on mapStyle
  const filteredGeoData = filterFeatures(geoData, mapStyle === 'nofly-zones');
  const filteredGeoDataNoNatura2000 = filterFeatures(geoDataNoNatura2000, mapStyle === 'nofly-zones');

  return (
    <>
      <MapGL
        ref={mapRef}
        initialViewState={initialViewState}
        mapStyle={mapStyleURL}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {/* Add the drones as a separate layer */}
        {memoizedDroneSources}

        {/* Render no-fly zone layers when data is available */}
        {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoData && (
          <Source type="geojson" data={filteredGeoData}>
            <Layer
              id="drone-zones"
              type="fill"
              paint={{
                'fill-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders"
              type="line"
              paint={{
                'line-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'line-width': 2,
              }}
            />
          </Source>
        )}

        {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoDataNoNatura2000 && (
          <Source type="geojson" data={filteredGeoDataNoNatura2000}>
            <Layer
              id="drone-zones-no-natura2000"
              type="fill"
              paint={{
                'fill-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders-no-natura2000"
              type="line"
              paint={{
                'line-color': [
                  'match',
                  ['get', 'localtype'],
                  'Verboden',
                  [
                    'case',
                    ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                    '#FF0000', // Red if 'sourceTxt' contains 'radio'
                    '#FFA500', // Orange otherwise
                  ],
                  'Natura2000',
                  '#3a7d15',
                  'Beperkt toegestaan',
                  '#FFA500',
                  '#CCCCCC', // Default color
                ],
                'line-width': 2,
              }}
            />
          </Source>
        )}
      </MapGL>
      <div className="overlay" />
    </>
  );
}
