import React from 'react';
import MissionCard from './MissionCard';

export default function MissionSelection({ missions, selectedMission, onMissionSelect, maxHeightClass }) {
  const sortedMissions = [...missions].sort((m1, m2) => new Date(m2.createdAt) - new Date(m1.createdAt));

  return (
    <div className={`overflow-y-auto flex flex-col space-y-4 rounded-lg pb-1 pr-2 ${maxHeightClass}`}>
      {sortedMissions.map((mission) => (
        <div
          key={mission.missionId}
          role="none"
          className="cursor-pointer"
          onClick={() => {
            onMissionSelect(mission);
          }}
        >
          <MissionCard
            mission={mission}
            isSelected={selectedMission && mission.missionId === selectedMission.missionId}
          />
        </div>
      ))}
    </div>
  );
}
