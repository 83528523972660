import React, { useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import MissionService from '../../services/Missions/mission.service';
import MissionMap from '../../components/Map/MissionMap';

/**
 *
 * This component renders the MissionMap.jsx but once loaded it centeres the map on the
 * mission waypoints.Currently used for the AutomaticChecks.jsx but should be also used in the
 * FlightReportDetail.jsx (that file need total refactoring)
 */

export default function CenteredMapView({ missionId }) {
  const mapStyle =
    localStorage.getItem('mapStyle') === 'nofly-zones' || localStorage.getItem('mapStyle') === 'nofly-zones-all'
      ? 'streets-v11'
      : localStorage.getItem('mapStyle') ?? 'streets-v11';
  const [lineStrings, setLineStrings] = useState(null);
  const [missionWaypoints, setMissionWaypoints] = useState();
  const mapRef = useRef();

  // Gets the waypoints of the mission by using the mission.service.js
  const getMissionWaypoints = async () => {
    const response = await MissionService.getMissionWaypoints(missionId);
    setMissionWaypoints(response.data);
    const coordinates = response.data.map((wp) => [wp.longitude, wp.latitude]);
    const lineString = turf.lineString(coordinates);
    setLineStrings(lineString);
  };

  // This function zooms the map on the selected mission (the waypoints)
  const getCoordinateCenter = () => {
    let minLat = null;
    let maxLat = null;
    let minLong = null;
    let maxLong = null;

    missionWaypoints.forEach((waypoint) => {
      minLat = waypoint.latitude < minLat || minLat == null ? waypoint.latitude : minLat;
      maxLat = waypoint.latitude > maxLat || maxLat == null ? waypoint.latitude : maxLat;
      minLong = waypoint.longitude < minLong || minLong == null ? waypoint.longitude : minLong;
      maxLong = waypoint.longitude > maxLong || maxLong == null ? waypoint.longitude : maxLong;
    });

    if (mapRef?.current) {
      mapRef.current.fitBounds(
        [
          [minLong, minLat],
          [maxLong, maxLat],
        ],
        { duration: 1000, maxZoom: 16 }
      );
    }
  };

  // First we get the waypoints and the lineStrings
  useEffect(() => {
    getMissionWaypoints();
  }, [missionId]);

  // Then we zoom the map on the provided coordinates
  useEffect(() => {
    if (missionWaypoints && missionWaypoints.length > 0) {
      getCoordinateCenter();
    }
  }, [missionWaypoints]); // This reacts to changes in missionWaypoints.

  return (
    <div style={{ height: '50vh', width: '100%' }}>
      <MissionMap
        waypoints={missionWaypoints || []}
        mapRef={mapRef}
        lineStrings={lineStrings}
        mapStyle={mapStyle}
        editable={null}
        selectedWaypoint={null}
      />
    </div>
  );
}
