import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { floor } from 'lodash';
import useSignalRConnection from '../../../hooks/useSignalRConnection';

export default function DroneCard({ drone, isSelected }) {
  const { telemetryData } = useSignalRConnection(drone.droneId);

  const batteryIcon = () => {
    if (telemetryData) {
      const battery = telemetryData.battery[0].remaining_percent;

      if (battery === 0) {
        return solid('battery-empty');
      }

      if (battery <= 5) {
        return solid('battery-low');
      }

      if (battery < 40) {
        return solid('battery-quarter');
      }

      if (battery < 60) {
        return solid('battery-half');
      }

      if (battery < 90) {
        return solid('battery-three-quarters');
      }

      if (battery >= 90) {
        return solid('battery-full');
      }

      return solid('battery-empty');
    }

    return solid('battery-empty');
  };

  return (
    <div className="w-full">
      <div
        className={`w-full p-4 bg-white rounded-lg border-2 !shadow-md min-w-[250px]
          ${telemetryData !== null ? 'border-[#A8D7B3]' : 'border-[#F9B2BB]'}
          ${isSelected ? '!border-blue ' : ''}`}
      >
        <div className="grid grid-cols-2 relative">
          <div className="col-span-2">
            <div className="grid grid-flow-col">
              <h2 className={`text-xl ${isSelected ? 'text-blue' : ''}`}>{drone.name}</h2>
            </div>
            <div className="absolute top-0 right-0 flex items-center StatusIndicator">
              <div className="relative">
                <FontAwesomeIcon
                  icon={solid('circle-dot')}
                  beat={telemetryData !== null}
                  className={`${telemetryData !== null ? 'text-green opacity-50' : 'text-red opacity-50'}`}
                  size="lg"
                />
                <FontAwesomeIcon
                  icon={solid('circle')}
                  className={`${telemetryData !== null ? 'text-green' : 'text-red'} relative left-[-15px]`}
                  size="2xs"
                />
              </div>
              <p>{telemetryData !== null ? 'online' : 'offline'}</p>
            </div>
          </div>
        </div>

        {telemetryData ? (
          <div className="pt-4 flex items-center justify-between">
            <div>
              <FontAwesomeIcon className="w-full" icon={solid('arrows-up-down')} />
              <p className="w-full flex-nowrap">{floor(telemetryData.gps[0].relative_altitude_m)} m</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={batteryIcon()} />
              <p className="w-full">{telemetryData.battery[0].remaining_percent} %</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={solid('clock-rotate-left')} />
              <p className="w-full">Time min</p>
            </div>

            <div>
              <FontAwesomeIcon className="w-full" icon={solid('timer')} />
              <p className="w-full">Speed km/h</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
