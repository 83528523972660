import axios from 'axios';

/* eslint-disable */
// TODO: someone has to really configure eslint properly because this is driving me crazy

// This is a  simple service that checks if frontend is opened on the device running remoteapp,
// which runs simple HTTP server on localhost:8080

const instance = axios.create({
  baseURL: `http://localhost:8080`,
});

const isAvailable = () => {
  return instance.get('/health')
    .then((res) => {
      return res.status === 200;
    })
    .catch((err) => false);
};

const exchangeLogin = (username, password) => {
  return instance.post('/login', {}, {
      params: {
        email: username,
        password: password
      },
    })
    .catch((err) => Promise.reject(err));
};

const exchangeLogout = () => {
  return instance.post('/logout')
    .catch((err) => Promise.reject(err));
};

const ExchangeAuthService = {
  isAvailable,
  exchangeLogin,
  exchangeLogout,
};

export default ExchangeAuthService;
