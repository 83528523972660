import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../shared.scss';
import StepperCheckManual from '../../components/StepperPhysicalPreperation/StepperCheckManual';
import { Stepper } from '../../components/Steppers/Stepper';
import { StepperButtons } from '../../components/Steppers/StepperButtons';

export default function ManualInspection() {
  const navigate = useNavigate();
  const [checkStep, setCheckStep] = useState(1);
  const steps = [['Safe Area'], ['Prepare Drone'], ['Check Drone'], ['Power On'], ['Step Back']];

  const nextCheck = (step) => {
    setCheckStep(step);
    if (step === steps.length + 1) {
      navigate('/automaticchecks');
    }
  };

  const prevCheck = (step) => {
    if (step === 0) {
      navigate('/schedule');
    }
    setCheckStep(step);
  };

  // TODO: if you have seen this code below, it would be nice to do it properly
  // TODO: Also please do not kill me for it. I just have HTML and Websites :)
  return (
    <div>
      <div className="flex flex-col md:flex-row pl-12 md:pl-0">
        {checkStep === 1 && (
          <StepperCheckManual
            stepTitle="Safe Area"
            stepDescription="<p>Om een veilige start en landing van de drone te garanderen, is het belangrijk om een geschikte locatie te kiezen. Hier zijn enkele richtlijnen om rekening mee te houden:<br></p>
          <ol style='list-style: inside;'>
            <li><b>Ruimte:</b> Zorg voor een vrij gebied van minstens 7 bij 7 meter, met de drone in het midden.</li>
            <li><b>Obstakels:</b> Controleer of er geen obstakels boven de start- en landingslocatie zijn, zoals bomen, kranen of hoogspanningsmasten, die de drone kunnen raken tijdens het opstijgen of landen.</li>
            <li><b>Ondergrond:</b> Vermijd locaties dicht bij water of met een ongelijkmatig oppervlak, omdat deze niet geschikt zijn voor veilig gebruik.</li>
          </ol>
          <p>Zodra u een geschikte locatie heeft gevonden, bereid deze dan voor door de landingsmat uit te vouwen en deze in het midden van uw gekozen start- en landingslocatie te plaatsen.</p>"
            imageName="safe-area"
          />
        )}
        {checkStep === 2 && (
          <StepperCheckManual
            stepTitle="Prepare Drone"
            stepDescription="
          <p>Zodra uw locatie is voorbereid, is het tijd om de drone uit de kist te halen. Volg deze stappen om de drone correct uit te vouwen:<br></p>
          <ol style='list-style: inside;'>
            <li><b>Uitvouwen van de Drone:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Begin met de voorste twee armen dicht bij de camera; beweeg deze naar voren.</li>
              <li>Klap vervolgens de twee achterste armen naar beneden om ze uit te vouwen. Let op: de achterste armen vouwen anders uit dan de voorste.</li>
            </ul></li>
            <li><b>Camera Bescherming Verwijderen:</b> Verwijder de camera-beschermer als laatste. Deze kan eenvoudig worden losgeklikt door lichte druk uit te oefenen op de onderkant van de beschermer.</li>
            <li><b>Controle:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Controleer of alle klepjes, zoals die over de USB-poort, goed gesloten zijn.</li>
              <li>Zorg ervoor dat er geen vuil of water op de camerales zit.</li>
            </ul></li>
          </ol>
          <p>Zodra u een geschikte locatie heeft gevonden, bereid deze dan voor door de landingsmat uit te vouwen en deze in het midden van uw gekozen start- en landingslocatie te plaatsen.</p>"
            imageName="prepare-drone"
          />
        )}
        {checkStep === 3 && (
          <StepperCheckManual
            stepTitle="Check Drone"
            stepDescription="<p>Plaats nu de drone in het midden van de landingsmat. Volg daarna deze stappen om de propellers voor te bereiden:<br></p>
          <ol style='list-style: inside;'>
            <li><b>Controle op Schade:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Vouw alle propellers uit zodat ze elkaar niet raken.</li>
            </ul></li>
            <li><b>Controle:</b> Controleer zorgvuldig de propellers op eventuele schade. Let op de volgende punten:
            <ul style='list-style: inside; padding-left: 15px;'>
              <li>Zijn er stukjes uit de propeller?</li>
              <li>Is er een gebarsten stop?</li>
            </ul></li>
          </ol>
          <p>Als u schade constateert, start dan de vlucht niet. Vliegen met beschadigde propellers is zeer gevaarlijk en kan leiden tot ongevallen.</p>"
            imageName="check-drone"
          />
        )}
        {checkStep === 4 && (
          <StepperCheckManual
            stepTitle="Power On"
            stepDescription="<p>Wanneer de drone veilig op de landingsmat staat, kunt u de drone en de afstandsbediening inschakelen. Volg deze stappen:<br></p>
          <ol style='list-style: inside;'>
            <li><b>Drone Inschakelen:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Druk op de aan-knop aan de achterkant van de drone en houd deze 1 seconde ingedrukt.</li>
              <li>Laat de knop los en druk vervolgens opnieuw in totdat alle vier de LED-lampjes op de achterkant van de drone verlicht zijn. Laat daarna de knop los.</li>
              <li>Wanneer de drone correct is ingeschakeld, maken de motoren een kleine beweging. Zorg ervoor dat de camera vrij kan bewegen tijdens dit proces.</li>
            </ul></li>
            <li><b>Afstandsbediening Inschakelen:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Zet de afstandsbediening aan met dezelfde procedure. De aan-knop bevindt zich bovenaan op de voorkant van de afstandsbediening.</li>
              <li>Zodra de afstandsbediening goed is ingeschakeld, ziet u het witte DJI-logo verschijnen.</li>
            </ul></li>
            <li><b>MRR App Openen:</b><ul style='list-style: inside; padding-left: 15px;'>
              <li>Wanneer de afstandsbediening is ingeschakeld, kunt u de MRR-app openen. Klik hiervoor op het app-icoontje op het startscherm van de afstandsbediening.</li>
            </ul></li>
          </ol>"
            imageName="power-on"
          />
        )}
        {checkStep === 5 && (
          <StepperCheckManual
            stepTitle="Step Back"
            stepDescription="<p>De drone is nu gereed om de automatische controles uit te voeren en op te stijgen. Zorg ervoor dat u en iedereen in de buurt voldoende afstand houden van de start- en landingslocatie tijdens het opstijgen, vliegen en landen van de drone.
          <br><br>
          Houd ten minste 5 meter afstand van de drone. Dit geldt ook voor materialen zoals voertuigen en gereedschap in de omgeving.</p>"
            imageName="move-back"
          />
        )}
        <Stepper nextCheckHandler={nextCheck} previousCheckHandler={prevCheck} steps={steps} />
      </div>
      <StepperButtons nextCheckHandler={nextCheck} previousCheckHandler={prevCheck} steps={steps} mobile />
    </div>
  );
}
