import api from '../api';

const getFlightReport = (flightReportId) =>
  api
    .get(`/flight-reports/${flightReportId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const getFlightReports = (query = '') =>
  api
    .get('/flight-reports', {
      params: { query },
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const saveFlightReport = (flightReport) =>
  api
    .post('/flight-reports', flightReport)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getImageNames = async (flightReportUuid) => {
  try {
    const res = await api.get(`/flight-reports/${flightReportUuid}/image-names`);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getImage = async (imageName) => {
  try {
    const res = await api.get(`/flight-reports/all/images/${encodeURIComponent(imageName)}`, {
      responseType: 'blob',
    });
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const deleteFlightReport = (id) =>
  api
    .delete(`/flight-reports/${id}/`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

// Sends either "cancel_take_off" or "take_off"
const sendDroneCommand = (droneId, flightId, command, speed, photoDelayMs, rotation, pitch) => {
  api
    .post('/missions/status', { droneId, flightId, status: command, speed, photoDelay: photoDelayMs, rotation, pitch })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));
};

const FlightReportService = {
  getFlightReports,
  getFlightReport,
  saveFlightReport,
  getImageNames,
  getImage,
  deleteFlightReport,
  sendDroneCommand,
};

export default FlightReportService;
