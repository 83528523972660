/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as turf from '@turf/turf';

import GenericService from '../../services/generic.service';
import TokenService from '../../services/Authentication/token.service';
import Badge from '../../components/App/Badge/Badge';
import MissionMap from '../../components/Map/MissionMap';
import Carousel from '../../components/Carousel/Carousel';

import '../shared.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/**
 * This component renders the "/data" page
 * which displays the mission flight report after the drone has lended
 *
 */

export default function FlightReportDetail({ flightReport }) {
  const mapStyle =
    localStorage.getItem('mapStyle') === 'nofly-zones' || localStorage.getItem('mapStyle') === 'nofly-zones-all'
      ? 'streets-v11'
      : localStorage.getItem('mapStyle') ?? 'streets-v11';
  const mapRef = useRef();
  const webWorker = new Worker(new URL('../../workers/fetchingImages.worker.js', import.meta.url));

  const [isDownloading, setIsDownloading] = React.useState(false);
  const [FlightReportApps, setFlightReportApps] = useState([]);

  const [isChartVisible, setChartVisible] = useState(false);

  const [lineStrings, setLineStrings] = useState(null);
  const [imageNames, setImageNames] = useState([]);
  const [images, setImages] = useState([]);

  const [downloadedImagesCount, setDownloadedImagesCount] = useState(0);

  const labels = ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Height',
        data: [4.8, 6, 5, 4.4, 4.9, 5.5, 4.8, 5.3, 5, 4.4, 4.9, 5.2],
        borderColor: '#4885CC',
        backgroundColor: '#E7F7FE',
        lineTension: 0.3,
      },
    ],
  };

  const toggleChartVisibility = () => {
    setChartVisible((prevVisibility) => !prevVisibility);
  };

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const getCoordinateCenter = () => {
    const waypoints = flightReport?.mission?.waypoints;
    let minLat = null;
    let maxLat = null;
    let minLong = null;
    let maxLong = null;

    waypoints.forEach((waypoint) => {
      minLat = waypoint.latitude < minLat || minLat == null ? waypoint.latitude : minLat;
      maxLat = waypoint.latitude > maxLat || maxLat == null ? waypoint.latitude : maxLat;
      minLong = waypoint.longitude < minLong || minLong == null ? waypoint.longitude : minLong;
      maxLong = waypoint.longitude > maxLong || maxLong == null ? waypoint.longitude : maxLong;
    });

    if (mapRef?.current) {
      mapRef.current.fitBounds(
        [
          [minLong, minLat],
          [maxLong, maxLat],
        ],
        { duration: 1000, maxZoom: 16 }
      );
    }
  };

  const fetchImageNames = async () => {
    const response = await GenericService.FlightReportService.getImageNames(flightReport?.s3Uuid);
    setImageNames(response);
  };

  const generateImagesZip = async () => {
    setIsDownloading(true);
    const zip = new JSZip();
    const pictureRetrievalPromises = images.map(async (image, index) => {
      zip.file(imageNames[index], image);
    });

    await Promise.all(pictureRetrievalPromises);

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `flight_${flightReport.s3Uuid}.zip`);
    setIsDownloading(false);
  };

  const deleteFlightReport = () => {
    GenericService.FlightReportService.deleteFlightReport(flightReport.flightReportId).then((response) => {
      if (response.status === 200) {
        window.toast.success('The flight report was removed successfully.');
      } else {
        window.toast.error('Something went wrong while removing the flight report, please try again.');
      }
    });
  };

  useEffect(() => {
    if (flightReport?.mission?.waypoints?.length >= 2) {
      const coordinates = flightReport?.mission?.waypoints.map((wp) => [wp.longitude, wp.latitude]);
      const lineString = turf.lineString(coordinates);
      setLineStrings(lineString);
    } else {
      setLineStrings(null);
    }
  }, [flightReport]);

  useEffect(() => {
    setTimeout(() => {
      if (flightReport?.mission?.waypoints?.length > 0) {
        getCoordinateCenter();
      }
    }, 10);
  }, [flightReport, mapRef.current]);

  const getFlightReportApps = async () => {
    GenericService.FlightReportAppService.getFlightReportAppsByFlightReportId(flightReport.flightReportId).then(
      (response) => {
        setFlightReportApps(response);
      }
    );
  };

  const getUpdatesMissionApps = async () => {
    try {
      const updatePromises = FlightReportApps.map(async (app) => {
        if (app.isProcessing) {
          await GenericService.MissionAppService.processingStatus(app.id);
        }
      });
      await Promise.all(updatePromises);
    } finally {
      await getFlightReportApps();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(getUpdatesMissionApps, 10000);
    return () => clearInterval(intervalId);
  }, [FlightReportApps, flightReport]);

  const RunApp = async (id) => {
    await GenericService.FlightReportAppService.manualStartProcessing(id);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    await getUpdatesMissionApps();
  };

  useEffect(() => {
    if (flightReport) {
      getFlightReportApps();
      fetchImageNames();
    }
  }, [flightReport]);

  useEffect(() => {
    const accessToken = TokenService.getLocalAccessToken();
    const maxConcurrentRequests = 5;
    setImages([]);
    setDownloadedImagesCount(0);

    webWorker.postMessage({ imageNames, accessToken, maxConcurrentRequests });
    const handleImageDownloadMessage = (event) => {
      const downloadedImages = event.data;
      setImages((prevPictures) => [...prevPictures, downloadedImages]);
      setDownloadedImagesCount((prevCount) => prevCount + 1);
    };

    webWorker.addEventListener('message', handleImageDownloadMessage);
    return () => {
      webWorker.terminate();
    };
  }, [imageNames]);

  return (
    <>
      {/* Title, mission link */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="mb-1 text-1xl font-bold tracking-tight text-gray-900">{flightReport?.mission?.name}</h2>
        </div>
        <div className="text-sm" style={{ color: 'rgb(37 99 235)' }}>
          <a href={`/missions/?mission=${flightReport?.missionId}`}>
            Go to mission <FontAwesomeIcon icon={solid('external-link')} />
          </a>
        </div>
      </div>

      {/* Metadata */}
      <div className="flex justify-between">
        <div className="flex space-x-4">
          <Badge type="success">Success</Badge>

          <div style={{ color: 'rgb(107 114 128)' }}>
            <FontAwesomeIcon style={{ fontSize: '15px' }} icon={regular('calendar-days')} />{' '}
            {new Date(flightReport?.executeAt).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })}
          </div>

          <div style={{ color: 'rgb(107 114 128)' }}>
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('clock')} /> 20m
          </div>
        </div>
        <div>
          <button
            className="bg-transparent hover:bg-red text-red font-semibold hover:text-white px-1 hover:border-transparent rounded"
            onClick={() => deleteFlightReport(flightReport?.flightReportId)}
            type="button"
          >
            Delete flight report
          </button>
        </div>
      </div>

      {/* Statistics */}
      <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px mt-3 my-1 border-0" />

      <div className="grid grid-cols-4">
        <div className="sm:flex sm:items-center justify-center">
          <div
            style={{
              backgroundColor: '#E7F7FE',
              color: '#4885CC',
            }}
            className="px-3 rounded-full"
          >
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('battery-bolt')} />
          </div>
          <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
            <h3 className="text-sm leading-6 font-medium text-gray-400 hidden lg:block">Battery Used</h3>
            <p className="text-1xl font-bold text-black">{randomIntFromInterval(50, 70)}%</p> {/* TODO: */}
          </div>
        </div>

        <div className="sm:flex sm:items-center justify-center">
          <div
            style={{
              backgroundColor: '#E7F7FE',
              color: '#4885CC',
            }}
            className="px-3 rounded-full"
          >
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('gauge-high')} />
          </div>
          <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
            <h3 className="text-sm leading-6 font-medium text-gray-400 hidden lg:block">Average Speed</h3>
            <p className="text-1xl font-bold text-black">{randomIntFromInterval(5, 6)}km/h</p> {/* TODO: */}
          </div>
        </div>

        <div className="sm:flex sm:items-center justify-center">
          <div
            style={{
              backgroundColor: '#E7F7FE',
              color: '#4885CC',
            }}
            className="px-3 rounded-full"
          >
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('arrow-down-up-across-line')} />
          </div>
          <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
            <h3 className="text-sm leading-6 font-medium text-gray-400 hidden lg:block">Average Height</h3>
            <p className="text-1xl font-bold text-black">{randomIntFromInterval(5, 6)}m</p> {/* TODO: */}
          </div>
        </div>

        <div className="sm:flex sm:items-center justify-center">
          <div
            style={{
              backgroundColor: '#E7F7FE',
              color: '#4885CC',
            }}
            className="px-3 rounded-full"
          >
            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={regular('location-dot')} />
          </div>
          <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
            <h3 className="text-sm leading-6 font-medium text-gray-400 hidden lg:block">Location</h3>
            <p className="text-1xl font-bold text-black">Eindhoven</p>
          </div>
        </div>
      </div>

      <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px mt-1 my-1 border-0" />

      {/* Mission data chart */}
      <button type="button" className="text-1xl font-bold text-black" onClick={toggleChartVisibility}>
        {isChartVisible ? 'Show less' : 'Show more'}
      </button>

      <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px mt-1 my-1 border-0 " />
      {isChartVisible && (
        <>
          <div style={{ height: '25vh' }}>
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    title: {
                      text: 'Height in meters',
                      display: true,
                    },
                  },
                  x: {
                    title: {
                      text: 'Datetime',
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={data}
            />
          </div>
          <div>
            <p>AWS ID: {flightReport.s3Uuid}</p>
          </div>
        </>
      )}

      <div className="grid grid-cols-12 gap-5 pt-5">
        <div className="col-span-6 relative w-full">
          <h6 className="mb-1 pt-3 text-1xl font-bold tracking-tight text-gray-900 ">Collected data</h6>
          <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px mt-1 my-1 border-0 " />

          <Carousel images={images} />
          <div className="flex justify-between pt-1">
            <div>
              {isDownloading ? (
                <button
                  disabled
                  style={{ backgroundColor: 'rgb(59 130 246)' }}
                  type="button"
                  className="flex items-center justify-center px-4 py-2 border border-transparent leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out"
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647z"
                    />
                  </svg>
                  Downloading...
                </button>
              ) : (
                imageNames.length > 0 && (
                  <button
                    type="button"
                    style={{ backgroundColor: 'rgb(59 130 246)' }}
                    className="bg-gray-300 text-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-3 inline-flex items-center rounded"
                    onClick={() => generateImagesZip()}
                  >
                    <span>Download zip</span>
                    <svg
                      style={{ fill: 'white' }}
                      className="fill-current w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                  </button>
                )
              )}
            </div>
            <div className="text-xs" style={{ color: 'rgb(107 114 128)' }}>
              Downloaded {downloadedImagesCount} out of {imageNames.length} images
            </div>
          </div>
          <div>
            {FlightReportApps.map((app) => (
              <div>
                <div>
                  {app.isProcessed ? (
                    <button
                      type="button"
                      style={{ backgroundColor: '#16a34a' }}
                      className="mt-3 bg-gray-300 text-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-3 rounded inline-flex items-center"
                      onClick={() => window.open(app.dataUrl, '_blank')}
                    >
                      <span>Open {app.app.name}</span>
                    </button>
                  ) : !app.isProcessing ? (
                    <button
                      type="button"
                      style={{ backgroundColor: 'rgb(59 130 246)' }}
                      className="mt-3 bg-gray-300 text-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-3 rounded inline-flex items-center"
                      onClick={() => RunApp(app.id)}
                    >
                      <span>Start {app.app.name}</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={{ backgroundColor: '#eab308' }}
                      className="mt-3 bg-gray-300 text-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-3 rounded inline-flex items-center"
                      disabled
                    >
                      <span>
                        {app.app.name}: {Math.round(app.processPercentage * 100) / 100}%
                      </span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-6">
          <h6 className="mb-1 pt-3 text-1xl font-bold tracking-tight text-gray-900 ">Mission View</h6>
          <hr style={{ backgroundColor: 'rgb(229 231 235)' }} className="h-px mt-1 my-1 border-0 " />

          <div style={{ height: '30vh' }}>
            <MissionMap
              waypoints={flightReport?.mission?.waypoints || []}
              mapRef={mapRef}
              lineStrings={lineStrings}
              mapStyle={mapStyle}
              editable={null}
              selectedWaypoint={null}
            />
          </div>
        </div>
      </div>

      {FlightReportApps.map((app) => (
        <div>
          {app.app.resultType === 2 && app.isProcessed && (
            <iframe src={app.dataUrl} title={app.app.name} width="100%" height="400" frameBorder="0" allowFullScreen />
          )}
        </div>
      ))}
    </>
  );
}
